import { render, staticRenderFns } from "./SecondaryButton.vue?vue&type=template&id=d2c5e966&scoped=true&"
import script from "./SecondaryButton.vue?vue&type=script&lang=ts&"
export * from "./SecondaryButton.vue?vue&type=script&lang=ts&"
import style0 from "./SecondaryButton.vue?vue&type=style&index=0&id=d2c5e966&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d2c5e966",
  null
  
)

export default component.exports